var WhatSystem = navigator.userAgent;

if(WhatSystem.match(/android/i)){
  // android系統
  var element = document.getElementById("btn-ios");
  element.classList.add("d-none");
}
else if(WhatSystem.match(/(iphone|ipad|ipod);?/i)){
  // ios系統
  var element = document.getElementById("btn-android");
  element.classList.add("d-none");
}
else{ 
  // 其他 
}

if(WhatSystem.match(/android/i)){
  // android系統
  var element = document.getElementById("iosdownload");
  element.classList.add("d-none");
}
else if(WhatSystem.match(/(iphone|ipad|ipod);?/i)){
  // ios系統
  var element = document.getElementById("androiddownload");
  element.classList.add("d-none");
}
